/* eslint-disable no-undef */
/* eslint-disable no-self-assign */
const lenisSettings = {
	duration: 2,
	easing: ( t ) => ( t === 1 ? 1 : 1 - Math.pow( 2, -10 * t ) ),
	direction: 'vertical',
	gestureDirection: 'vertical',
	smooth: true,
	smoothTouch: false,
	wheelMultiplier: 0.8,
	touchMultiplier: 0.2,
	// mouseMultiplier: 0.2,
	inifinite: false,
}
const lenis = new Lenis( lenisSettings );
lenis.on( 'scroll', ( e ) => {
	// console.log(e)
});
function raf( time ) {
	lenis.raf( time );
	// ScrollTrigger.update();
	requestAnimationFrame( raf );
}
requestAnimationFrame( raf );

function toggleScroll( isScroll = true ) {
	if ( isScroll ) {
		lenis.start();
	} else {
		lenis.stop();
	}
}

gsap.registerPlugin( ScrollTrigger );
// ScrollTrigger.normalizeScroll(true);

const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

let preloaderTime = preloader?.classList.contains('show') ? 3000 : 0;
document.addEventListener('scroll', () => {
	preloaderTime = preloader?.classList.contains('show') ? 3000 : 0;
}, false);

function initAOS() {
	AOS.init({
		duration: 800,
		once: true,
		easing: 'ease-out-quad',
		offset: 50,
		anchorPlacement: 'bottom-bottom',
	});
}
setTimeout( initAOS, preloaderTime );

/* - - - - - - - - - - - - - -       fixed header       - - - - - - - - - - - - - - - */
let lastScrollTop = 0;
function fixedHeader() {
	const top = $( window ).scrollTop();
	if ( top > 0 ) {
		$( '.header' ).addClass( 'fixed' );
	} else {
		$( '.header' ).removeClass( 'fixed' );
	}
	lastScrollTop = top;
}
fixedHeader();
$( window ).on( 'scroll', fixedHeader );

function langToggle() {
	var toggle = $(".js-lang span");
	var list = $(".js-lang .lang-list");
	toggle.on("click", function() {
		list.toggleClass("open");
	} );

	// close lang menu on select lang
	$(".js-lang .lang-list a").on("click", function() {
		list.removeClass("open");
	} );

	// close lang menu on click outside
	$(document).click(function(event) {
		if (!$(event.target).closest(".js-lang, .lang-list ").length) {
			if ($(".lang-list").hasClass("open")) {
				$(".lang-list").removeClass("open");
			}
		}
	} );
}

function menuToggle() {
	// toggle burder menu
	$("#burger").click(function() {
		if (!$(this).hasClass("toggle")) {
			toggleScroll(false);
			$(".header").addClass("show-menu");
			$(this).addClass("toggle");
			$("#menu").addClass("active");
		} else {
			toggleScroll(true);
			$(".header").removeClass("show-menu");
			$(this).removeClass("toggle");
			$("#menu").removeClass("active");
		}
	});
}

function anchorScroll() {
	$("nav ul li a").click(function(event) {
		$("nav ul li a").removeClass("active");
		$(this).addClass("active");

		const speed = 1000; // px/second
		const top = $(window).scrollTop();

		var href = $(this.hash);
		let posTop = 0;

		if (href.length) {
			event.preventDefault();
			let offsetTop = 0;
			if ( this.hash === "#we-are" ) {
				offsetTop = parseInt( $( ".main" ).css( "padding-top" ) );
			}
			if ( this.hash === "#our-strategy" ) {
				offsetTop = -parseInt( $( ".strategies" ).css( "padding-top" ) );
			}
			if ( this.hash === "#our-approach" ) {
				offsetTop = $( window ).innerHeight() / 2 - parseInt($( ".approach" ).css( "padding-top" )) - $( ".approach-title" ).innerHeight() / 2;
			}
			posTop = href.offset().top - offsetTop;

			$("html, body").stop().animate( {
				scrollTop: posTop
			}, {
				duration: Math.abs(posTop - top) / speed * 1000,
				easing: "linear"
			},
			function() {
				if (history.pushState) {
					history.pushState(null, null, "/#" + href.attr("id"));
				} else {
					location.hash = href.attr("id");
				}
			} );
			if ($("#menu").hasClass("active")) {
				toggleScroll(true);
				$(".header").removeClass("show-menu");
				$("#menu").removeClass("active");
				$("#burger").removeClass("toggle");
			}
		}
	} );
}

$(document).ready(function() {
	langToggle();
	menuToggle();
	anchorScroll();

	let ww = $(window).innerWidth();
	$(window).on("resize", function () {
		if (ww !== $(window).innerWidth()) {
			ww = $(window).innerWidth();
		}
		if ($(".smartphone").css("display") === "block") {
			toggleScroll(false);
		} else {
			toggleScroll(true);
		}
	});

	// modal default settings
	$.modal.defaults = {
		closeClass: "close",
		showClose: true,
		closeText: "<span class='icon icon-i-close'></span>",
		blockerClass: "jquery-modal"
	};

	// close menu on click outside
	$(document).click(function (event) {
		if (!$(event.target).closest("#burger, #menu").length) {
			if ($("#menu").hasClass("active")) {
				toggleScroll(true);
				$(".header").removeClass("show-menu");
				$("#menu").removeClass("active");
				$("#burger").removeClass("toggle");
			}
		}
	});

	// if ($(".intro").length) {
	// 	gsap.timeline({
	// 		scrollTrigger: {
	// 			trigger: ".intro",
	// 			start: 'top top',
	// 			endTrigger: '.intro-counter',
	// 			end: 'center center',
	// 			scrub: true,
	// 			invalidateOnRefresh: true,
	// 			// markers: true,
	// 		}
	// 	}).fromTo( "#coneCanvas", {
	// 		opacity: 1,
	// 		y: 0
	// 	}, {
	// 		opacity: 0.5,
	// 		y: 100
	// 	});
	// }

	if ($(".intro-counter").length) {
		const cardList = gsap.utils.toArray(".counter-card");
		ScrollTrigger.matchMedia({
			"(min-width: 768px)": () => {
				gsap.timeline({
					scrollTrigger: {
						trigger: '.intro-counter',
						pin: true,
						scrub: true,
						start: 'center center',
						end: `+=${window.innerHeight + window.innerHeight / 2}`,
						invalidateOnRefresh: true,
						// markers: true
					}
				});
				const tlIntro = gsap.timeline({
					scrollTrigger: {
						trigger: '.intro-counter',
						start: 'center center',
						end: `+=${window.innerHeight}`,
						scrub: true,
						invalidateOnRefresh: true,
						// markers: true,
					}
				});
				tlIntro.fromTo(cardList[0], {
					opacity: 0,
					duration: 1
				}, {
					opacity: 1
				}, 0)
					.fromTo(cardList[0].querySelector('.counter-card__count'), {
						y: 30,
						opacity: 0,
						duration: 1
					}, {
						y: 0,
						opacity: 1
					}, 0.2)
					.fromTo(cardList[1], {
						opacity: 0,
						duration: 1
					}, {
						opacity: 1
					}, 0.2)
					.fromTo(cardList[1].querySelector('.counter-card__count'), {
						y: 30,
						opacity: 0,
						duration: 1
					}, {
						y: 0,
						opacity: 1
					}, 0.4)
					.fromTo(cardList[2], {
						opacity: 0,
						duration: 1
					}, {
						opacity: 1
					}, 0.4)
					.fromTo(cardList[2].querySelector('.counter-card__count'), {
						y: 30,
						opacity: 0,
						duration: 1
					}, {
						y: 0,
						opacity: 1
					}, 0.6);
			},
			"(max-width: 767px)": () => {
				cardList.forEach((card, i) => {
					gsap.to(card, {
						scrollTrigger: {
							trigger: card,
							start: 'center bottom',
							end: 'bottom top',
							scrub: true,
							invalidateOnRefresh: true,
							// markers: true,
							onEnter: function () {
								setTimeout(() => {
									card.classList.add('show');
								}, preloaderTime);
							},
						}
					});
				});
			}
		});
	}

	let heightStrategy = 0;

	function getHeightStrategy() {
		heightStrategy = $('.strategyCard').innerHeight();
	}

	if ($(".strategyCard").length) {
		getHeightStrategy();
		window.addEventListener('resize', getHeightStrategy, false);

		const strategyList = gsap.utils.toArray(".strategyCard");
		const count = strategyList.length;
		// gsap.timeline({
		// 	scrollTrigger: {
		// 		trigger: '.strategies-inner',
		// 		pin: true,
		// 		scrub: 0,
		// 		start: 'center center',
		// 		end: () => `+=${heightStrategy * count}`,
		// 		invalidateOnRefresh: true,
		// 		// markers: true
		// 	}
		// });
		const tlStrategy = gsap.timeline({
			scrollTrigger: {
				trigger: '.strategies-inner',
				scrub: true,
				pin: true,
				start: 'center center',
				end: () => `+=${heightStrategy * count * 2}`,
				invalidateOnRefresh: true,
				// markers: true
			}
		});
		ScrollTrigger.matchMedia({
			"(min-width: 768px)": () => {
				tlStrategy.to('.strategies-title', {
					x: () => -window.innerWidth / 4,
					y: 0,
					opacity: 0,
				});
			},
			"(max-width: 767px)": () => {
				tlStrategy.to('.strategies-title', {
					x: 0,
					y: () => -40,
					opacity: 0,
				});
			}
		});

		ScrollTrigger.matchMedia({
			"(min-width: 1200px)": () => {
				strategyList.forEach((card, i) => {
					const pos = i ? '+=0.5' : '';
					const cardWidth = strategyList[0].getBoundingClientRect().width;
					const posLeft = -window.innerWidth / 2 - cardWidth / 3 * 2;

					tlStrategy.fromTo(strategyList[0], {
						ease: "linear",
						x: () => {
							if (i === 0) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 1.5;
							}
						},
						y: 0,
						background: 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000 100%)',
						zIndex: count
					}, {
						ease: "linear",
						x: () => {
							if (i === 0) {
								return 0;
							}
							if (i === 1) {
								return posLeft;
							}
						},
						y: 0,
						duration: 1
					}, pos)
						.fromTo(strategyList[1], {
							ease: "linear",
							scale: () => {
								if (i === 0) {
									return 0.9;
								}
								if (i === 1) {
									return 1;
								}
							},
							x: () => {
								if (i === 0) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 3.5;
								}
								if (i === 1) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 1.5;
								}
							},
							y: 0,
							background: () => {
								if (i === 0) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
								}
								if (i === 1) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
								}
							},
							zIndex: count - 1
						}, {
							ease: "linear",
							scale: () => {
								if (i === 0) {
									return 1;
								}
							},
							x: () => {
								if (i === 0) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 1.5;
								}
								if (i === 1) {
									return 0;
								}
								if (i === 2) {
									return posLeft;
								}
							},
							y: 0,
							background: () => {
								if (i === 0) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
								}
							},
							duration: 1
						}, '-=1')
						.fromTo(strategyList[2], {
							ease: "linear",
							scale: () => {
								if (i === 0) {
									return 0.8;
								}
								if (i === 1) {
									return 0.9;
								}
								if (i === 2) {
									return 1;
								}
							},
							x: () => {
								if (i === 0) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 5.5;
								}
								if (i === 1) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 3.5;
								}
								if (i === 2) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 1.5;
								}
							},
							y: 0,
							background: () => {
								if (i === 0) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #243D8E 0%, #111567 100%)';
								}
								if (i === 1) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
								}
								if (i === 2) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
								}
							},
							zIndex: count - 2
						}, {
							ease: "linear",
							scale: () => {
								if (i === 0) {
									return 0.9;
								}
								if (i === 1) {
									return 1;
								}
							},
							x: () => {
								if (i === 0) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 3.5;
								}
								if (i === 1) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 1.5;
								}
								if (i === 2) {
									return 0;
								}
								if (i === 3) {
									return posLeft;
								}
							},
							y: 0,
							background: () => {
								if (i === 0) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
								}
								if (i === 1) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
								}
							},
							duration: 1
						}, '-=1');
				});
			},
			"(min-width: 768px) and (max-width: 1199px)": () => {
				strategyList.forEach((card, i) => {
					const pos = i ? '+=0.5' : '';
					const cardWidth = strategyList[0].getBoundingClientRect().width;
					const posLeft = -window.innerWidth / 2 - cardWidth / 3 * 2;
					tlStrategy.fromTo(strategyList[0], {
						x: () => {
							if (i === 0) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 1.5;
							}
						},
						y: 0,
						background: 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000 100%)',
						zIndex: count
					}, {
						x: () => {
							if (i === 0) {
								return 0;
							}
							if (i === 1) {
								return posLeft;
							}
						},
						y: 0,
						duration: 1
					}, pos)
						.fromTo(strategyList[1], {
							scale: () => {
								if (i === 0) {
									return 0.95;
								}
								if (i === 1) {
									return 1;
								}
							},
							x: () => {
								if (i === 0) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 3.5;
								}
								if (i === 1) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 1.5;
								}
							},
							y: 0,
							background: () => {
								if (i === 0) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
								}
								if (i === 1) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
								}
							},
							zIndex: count - 1
						}, {
							scale: () => {
								if (i === 0) {
									return 1;
								}
							},
							x: () => {
								if (i === 0) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 1.5;
								}
								if (i === 1) {
									return 0;
								}
								if (i === 2) {
									return posLeft;
								}
							},
							y: 0,
							background: () => {
								if (i === 0) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
								}
							},
							duration: 1
						}, '-=1')
						.fromTo(strategyList[2], {
							scale: () => {
								if (i === 0) {
									return 0.9;
								}
								if (i === 1) {
									return 0.95;
								}
								if (i === 2) {
									return 1;
								}
							},
							x: () => {
								if (i === 0) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 5.5;
								}
								if (i === 1) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 3.5;
								}
								if (i === 2) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 1.5;
								}
							},
							y: 0,
							background: () => {
								if (i === 0) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #243D8E 0%, #111567 100%)';
								}
								if (i === 1) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
								}
								if (i === 2) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
								}
							},
							zIndex: count - 2
						}, {
							scale: () => {
								if (i === 0) {
									return 0.95;
								}
								if (i === 1) {
									return 1;
								}
							},
							x: () => {
								if (i === 0) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 3.5;
								}
								if (i === 1) {
									return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 1.5;
								}
								if (i === 2) {
									return 0;
								}
								if (i === 3) {
									return posLeft;
								}
							},
							y: 0,
							background: () => {
								if (i === 0) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
								}
								if (i === 1) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
								}
							},
							duration: 1
						}, '-=1');
				});
			},
			"(max-width: 767px)": () => {
				strategyList.forEach((card, i) => {
					const pos = i ? '+=0.5' : '';
					tlStrategy.fromTo(strategyList[0], {
						x: 0,
						y: () => {
							if (i === 0) {
								return window.innerHeight;
							}
							if (i === 1) {
								return 0;
							}
							if (i === 2) {
								return -30;
							}
							if (i === 3) {
								return -60;
							}
						},
						background: () => {
							if (i <= 1) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000 100%)';
							}
							if (i === 2) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
							}
						},
						zIndex: ''
					}, {
						scale: () => {
							if (i === 0) {
								return 1;
							}
							if (i === 1) {
								return 0.95;
							}
							if (i === 2) {
								return 0.9;
							}
							if (i === 3) {
								return 0.85;
							}
						},
						x: 0,
						y: () => {
							if (i === 0) {
								return 0;
							}
							if (i === 1) {
								return -30;
							}
							if (i === 2) {
								return -60;
							}
							if (i === 3) {
								return -90;
							}
						},
						background: () => {
							if (i === 0) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
							}
							if (i === 1) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
							}
							if (i === 2) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #243D8E 0%, #111567 100%)';
							}
						},
						duration: 1
					}, pos)
						.fromTo(strategyList[1], {
							x: 0,
							y: () => {
								if (i <= 1) {
									return window.innerHeight;
								}
								if (i === 2) {
									return 0;
								}
								if (i === 3) {
									return -30;
								}
							},
							background: () => {
								if (i <= 2) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000 100%)';
								}
								if (i === 3) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
								}
							},
							zIndex: ''
						}, {
							scale: () => {
								if (i === 1) {
									return 1;
								}
								if (i === 2) {
									return 0.95;
								}
								if (i === 3) {
									return 0.9;
								}
							},
							x: 0,
							y: () => {
								if (i === 1) {
									return 0;
								}
								if (i === 2) {
									return -30;
								}
								if (i === 3) {
									return -60;
								}
							},
							background: () => {
								if (i === 1) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
								}
								if (i === 2) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
								}
							},
							duration: 1
						}, '-=1')
						.fromTo(strategyList[2], {
							x: 0,
							y: () => {
								if (i === 2) {
									return window.innerHeight;
								}
							},
							background: 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000 100%)',
							zIndex: ''
						}, {
							scale: () => {
								if (i === 2) {
									return 1;
								}
								if (i === 3) {
									return 0.95;
								}
							},
							x: 0,
							y: () => {
								if (i === 2) {
									return 0;
								}
								if (i === 3) {
									return -30;
								}
							},
							background: () => {
								if (i === 2) {
									return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
								}
							},
							duration: 1
						}, '-=1');
				});
			}
		});
	}

	// if ( $( "#gridCanvas" ).length ) {
	// 	gsap.timeline({
	// 		scrollTrigger: {
	// 			trigger: '.mapBlock-map',
	// 			scrub: true,
	// 			start: 'top 75%',
	// 			end: 'top 25%',
	// 			invalidateOnRefresh: true,
	// 			// markers: true
	// 		}
	// 	}).fromTo( '#gridCanvas', {
	// 		scale: 2.5,
	// 		rotationX: 90,
	// 		opacity: 0,
	// 		duration: 1
	// 	}, {
	// 		scale: 1,
	// 		rotationX: 0,
	// 		opacity: 0.5
	// 	});
	// }

	if ($(".mapBlock-map").length) {
		ScrollTrigger.matchMedia({
			"(min-width: 768px)": () => {
				gsap.timeline({
					scrollTrigger: {
						trigger: '.mapBlock',
						scrub: true,
						pin: true,
						// start: () => `top top+=${$('.header').innerHeight()}`,
						start: `top top`,
						end: `+=${$(window).innerHeight() / 2}`,
						invalidateOnRefresh: true,
						// markers: true
					}
				});
			}
		});

		gsap.timeline({
			scrollTrigger: {
				trigger: '.mapBlock-map',
				scrub: true,
				start: 'top 75%',
				end: 'top 25%',
				invalidateOnRefresh: true,
				// markers: true
			}
		}).fromTo('.mapBlock-map-vector', {
			scale: 2.5,
			opacity: 0,
			duration: 1
		}, {
			scale: 1,
			opacity: 1
		}, 0);

		gsap.timeline({
			scrollTrigger: {
				trigger: '.mapBlock-map',
				scrub: true,
				start: 'top 75%',
				end: 'top 50%',
				invalidateOnRefresh: true,
				// markers: true
			}
		}).fromTo('.mapBlock-map-vector', {
			rotationX: 90,
			duration: 1
		}, {
			rotationX: 0,
		}).fromTo('#gridAngleCanvas', {
			opacity: 1
		}, {
			opacity: 0
		}, '-=0.5').fromTo('.mapBlock-map-title', {
			y: 60,
			opacity: 0,
			duration: 1
		}, {
			y: 0,
			opacity: 1
		}, '-=0.25');
	}

	if ($(".textBlock").length) {
		const target = document.querySelector(".textBlock");
		const results = Splitting({target: target, by: "chars"});

		const textAnimation = gsap.timeline({
			scrollTrigger: {
				trigger: ".textBlock",
				scrub: true,
				start: "center center",
				end: () => `center+=${$('.filledText').innerHeight() * 3} center`,
				pin: true,
				invalidateOnRefresh: true,
				// markers: true
			}
		}).to(".textBlock .char", {
			"--highlight-offset": "100%",
			stagger: 0.5
		});
	}

	let heightApproach = 0;

	function getHeightApproach() {
		heightApproach = $('.approach-card-main').innerHeight();
	}

	if ($(".approach-row").length) {
		getHeightApproach();
		window.addEventListener('resize', getHeightApproach, false);

		const approachList = gsap.utils.toArray(".approach-card-main");
		const approachTitle = gsap.utils.toArray('.approach-card-after');
		const count = approachList.length;
		gsap.timeline({
			scrollTrigger: {
				trigger: '.approach-row',
				pin: true,
				scrub: 0,
				start: 'center center',
				end: `+=${heightApproach * (count * 1.5) + window.innerHeight / 2}`,
				invalidateOnRefresh: true,
				// markers: true
			}
		});
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.approach-row',
				scrub: 0,
				start: 'center center',
				end: `+=${heightApproach * (count * 1.5)}`,
				invalidateOnRefresh: true,
				// markers: true
			}
		});
		approachList.forEach((card, i) => {
			const pos = i ? '+=0.5' : '';
			tl
				.fromTo(approachList[i], {
					ease: "linear",
					opacity: 0,
					scale: 1,
					y: 300
				}, {
					ease: "linear",
					opacity: 1,
					scale: 1,
					y: 0
				}, pos)
				.to(approachList[i - 1], {
					ease: "linear",
					opacity: 0.8,
					scale: 0.9,
					y: -36
				}, "-=0.5")
				.fromTo(approachTitle[i], {
					ease: "linear",
					opacity: 0,
					y: 100
				}, {
					ease: "linear",
					opacity: 1,
					y: 0
				}, '<')
				.to(approachTitle[i - 1], {
					ease: "linear",
					opacity: 0,
					y: -36
				}, '-=0.5');
		});
	}

	if ($(".userCard").length) {
		const teamList = gsap.utils.toArray(".userCard");
		const mm = gsap.matchMedia();
		mm.add("(min-width: 1024px)", () => {
			teamList.forEach((card, i) => {
				gsap.to(card, {
					scrollTrigger: {
						trigger: card,
						start: 'center bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
						// markers: true,
						onEnter: function () {
							setTimeout(() => {
								card.querySelector('.userCard-box').classList.add('show');
							}, preloaderTime);
						},
					}
				});
			});
		}).add("(max-width: 1023px)", () => {
			teamList.forEach((card, i) => {
				gsap.to(card, {
					scrollTrigger: {
						trigger: card,
						start: 'center bottom',
						end: 'bottom top',
						scrub: true,
						// markers: true,
						onEnter: function () {
							setTimeout(() => {
								card.querySelector('.userCard-box').classList.add('show');
							}, preloaderTime);
						},
					}
				});
			});
		});
	}

	if ($(".team").length) {
		ScrollTrigger.matchMedia({
			"(min-width: 768px)": () => {
				gsap.timeline({
					scrollTrigger: {
						trigger: ".team",
						start: 'bottom bottom',
						end: `bottom+=${window.innerHeight / 2}} bottom`,
						scrub: true,
						pin: true,
						// markers: true,
					}
				});
			}
		});
	}

	if ($(".brands").length) {
		gsap.timeline({
			scrollTrigger: {
				trigger: ".brands",
				start: 'top top',
				end: `+=${window.innerHeight / 2}}`,
				scrub: true,
				pin: true,
				// markers: true,
			}
		});
	}

	// if ( $( "#gridCanvas" ).length ) {
	// 	gsap.timeline({
	// 		scrollTrigger: {
	// 			trigger: '.brands',
	// 			scrub: true,
	// 			start: 'top bottom',
	// 			end: 'top 50%',
	// 			invalidateOnRefresh: true,
	// 			// markers: true
	// 		}
	// 	}).fromTo( '#gridCanvas', {
	// 		scale: 1,
	// 		opacity: 0.5,
	// 		duration: 1
	// 	}, {
	// 		scale: 0,
	// 		opacity: 0
	// 	});
	// }

	if ($(".conceptBlock").length) {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.conceptBlock',
				pin: true,
				scrub: 0,
				start: 'top bottom',
				end: () => `top center-=${$('.header').innerHeight() + 36}`,
				invalidateOnRefresh: true,
				// markers: true,
				// onEnterBack: () => {
				// 	$( '.footer' ).removeClass( 'fixed' );
				// },
				// onLeave: () => {
				// 	$( '.footer' ).addClass( 'fixed' );
				// }
			}
		}).fromTo('.conceptBlock-left', {
				x: () => -window.innerWidth / 2
			},
			{
				x: 0
			}, '<'
		).fromTo('.conceptBlock-right', {
				x: () => window.innerWidth / 2
			},
			{
				x: 0
			}, '<'
		).fromTo('.header-bg', {
				opacity: 0.8
			},
			{
				opacity: 0
			}, '<'
		).to('.conceptBlock-line', {
				opacity: 1,
				duration: 0
			}
		).to('.conceptBlock-line', {
				height: 32
			}
		);

		gsap.timeline({
			scrollTrigger: {
				trigger: '.conceptBlock-bg',
				pin: true,
				scrub: 0,
				start: 'top top',
				end: () => `top+=100% top`,
				invalidateOnRefresh: true,
			}
		}).fromTo('.conceptBlock-bg', {
				opacity: 0
			},
			{
				opacity: 1,
				scrollTrigger: {
					trigger: '.conceptBlock-bg',
					start: 'top top', // Початок зміни opacity
					end: 'top+=25% top', // Кінець зміни opacity (в середині скролу)
					scrub: true, // Плавність під час скролу
					invalidateOnRefresh: true,
				}
			});

		const tl2 = gsap.timeline({
			scrollTrigger: {
				trigger: '.footer',
				scrub: 0,
				start: 'top 50%',
				end: 'bottom bottom',
				invalidateOnRefresh: true,
				// markers: true,
			}
		})
			.fromTo('.conceptBlock-main', {
				opacity: 0
			}, {
				opacity: 1
			});

		gsap.timeline({
			scrollTrigger: {
				trigger: '.footer',
				scrub: 0,
				start: 'top 5%',
				end: 'bottom bottom',
				invalidateOnRefresh: true
			}
		})
			.fromTo('.footer-inner', {
				opacity: 0
			}, {
				opacity: 1
			});
	}

	function imagesLoaded(container, callback) {
		const images = container.querySelectorAll('img');
		const promises = [];

		images.forEach((img) => {
			const promise = new Promise((resolve, reject) => {
				if (img.complete) {
					resolve(); // Зображення вже завантажене
				} else {
					img.addEventListener('load', resolve); // Додаємо обробник події "load"
					img.addEventListener('error', reject); // Додаємо обробник події "error"
				}
			});
			promises.push(promise);
		});

		Promise.all(promises)
			.then(callback) // Виконуємо callback, коли всі зображення завантажені
			.catch((error) => console.error('Some images failed to load', error));
	}

	/*
    This helper function makes a group of elements animate along the x-axis in a seamless, responsive loop.
    Features:
     - Uses xPercent so that even if the widths change (like if the window gets resized), it should still work in most cases.
     - When each item animates to the left or right enough, it will loop back to the other side
     - Optionally pass in a config object with values like "speed" (default: 1, which travels at roughly 100 pixels per second), paused (boolean),  repeat, reversed, and paddingRight.
     - The returned timeline will have the following methods added to it:
       - next() - animates to the next element using a timeline.tweenTo() which it returns. You can pass in a vars object to control duration, easing, etc.
       - previous() - animates to the previous element using a timeline.tweenTo() which it returns. You can pass in a vars object to control duration, easing, etc.
       - toIndex() - pass in a zero-based index value of the element that it should animate to, and optionally pass in a vars object to control duration, easing, etc. Always goes in the shortest direction
       - current() - returns the current index (if an animation is in-progress, it reflects the final index)
       - times - an Array of the times on the timeline where each element hits the "starting" spot. There's also a label added accordingly, so "label1" is when the 2nd element reaches the start.
     */
	function horizontalLoop(items, config) {
		items = gsap.utils.toArray(items);
		config = config || {};
		let tl = gsap.timeline({
				repeat: config.repeat,
				paused: config.paused,
				defaults: {ease: "none"},
				invalidateOnRefresh: true,
				onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
			}),
			length = items.length,
			startX = items[0].offsetLeft,
			times = [],
			widths = [],
			xPercents = [],
			curIndex = 0,
			pixelsPerSecond = (config.speed || 1) * 100,
			snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
			totalWidth, curX, distanceToStart, distanceToLoop, item, i;
		gsap.set(items, { // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
			xPercent: (i, el) => {
				let w = widths[i] = parseFloat(gsap.getProperty(el, "width", "px"));
				xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / w * 100 + gsap.getProperty(el, "xPercent"));
				return xPercents[i];
			}
		});
		gsap.set(items, {x: 0});
		totalWidth = items[length - 1].offsetLeft + xPercents[length - 1] / 100 * widths[length - 1] - startX + items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") + (parseFloat(config.paddingRight) || 0);
		for (i = 0; i < length; i++) {
			item = items[i];
			curX = xPercents[i] / 100 * widths[i];
			distanceToStart = item.offsetLeft + curX - startX;
			distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
			tl.to(item, {
				xPercent: snap((curX - distanceToLoop) / widths[i] * 100),
				duration: distanceToLoop / pixelsPerSecond
			}, 0)
				.fromTo(item, {xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100)}, {
					xPercent: xPercents[i],
					duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
					immediateRender: false
				}, distanceToLoop / pixelsPerSecond)
				.add("label" + i, distanceToStart / pixelsPerSecond);
			times[i] = distanceToStart / pixelsPerSecond;
		}

		function toIndex(index, vars) {
			vars = vars || {};
			(Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
			let newIndex = gsap.utils.wrap(0, length, index),
				time = times[newIndex];
			if (time > tl.time() !== index > curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
				vars.modifiers = {time: gsap.utils.wrap(0, tl.duration())};
				time += tl.duration() * (index > curIndex ? 1 : -1);
			}
			curIndex = newIndex;
			vars.overwrite = true;
			return tl.tweenTo(time, vars);
		}

		tl.next = vars => toIndex(curIndex + 1, vars);
		tl.previous = vars => toIndex(curIndex - 1, vars);
		tl.current = () => curIndex;
		tl.toIndex = (index, vars) => toIndex(index, vars);
		tl.times = times;
		tl.progress(1, true).progress(0, true); // pre-render for performance
		if (config.reversed) {
			tl.vars.onReverseComplete();
			tl.reverse();
		}
		return tl;
	}

	const gsapMarqueeList = document.querySelectorAll(".js-gsap-marquee");
	gsapMarqueeList.forEach(gsapMarquee => {
		let isReversed = false;
		if (gsapMarquee.classList.contains('reversed')) {
			isReversed = true;
		}
		const images = gsapMarquee.querySelectorAll('img');
		if (images.length) {
			imagesLoaded(gsapMarquee, () => {
				// console.log('All images are loaded!');
				// Тут виконається код після завантаження всіх зображень

				horizontalLoop(gsapMarquee.children, {
					paused: false,
					repeat: -1,
					speed: 0.5,
					reversed: isReversed
				});
			});
		} else {
			setTimeout(() => {
				horizontalLoop(gsapMarquee.children, {
					paused: false,
					repeat: -1,
					speed: 0.5,
					reversed: isReversed
				});
			}, 1000);
		}
	});


	const morphing = document.querySelector(".morphing");
	if (morphing) {
		// Settings
		const pointTexture = sphereIcon;
		const pointSize = 0.2;

		const planeWidth = 30;
		const planeSegmentsQty = 34;
		const sphereRadius = 5;

		const coneOuterHeight = 5.5;
		const coneOuterRadiusTop = 8.5;
		const coneOuterRadiusBottom = 2;
		const coneOuterSegmentsQty = 20;

		const coneInnerHeight = 5.5;
		const coneInnerRadiusTop = 7.5;
		const coneInnerRadiusBottom = 1.2;
		const coneInnerSegmentsQty = 16;

		const infinitePlaneWidth = 200;
		const infinitePlaneSegments = 44;
		const infinitePlaneDepth = 1200;

		const cameraDistance = 50;


		// ----------------------------------------------------------------------------
		// *** Here's the short instruction for setting up more precise scroll points: ***

		// * 1. Relative to entire page height:
		// Calculate the scroll points as a percentage of the total page height.
		// Example: scrollPoint1 = document.body.scrollHeight * 0.2; // 20% of total page height

		// * 2. Relative to specific element's position:
		// Use the position of a specific element to define the scroll point.
		// Example: scrollPoint2 = document.querySelector('#elementId').offsetTop; // Position of an element

		// * 3. Relative to cumulative content height:
		// Set scroll points based on the cumulative height of content before the desired point.
		// Example: scrollPoint3 = document.querySelector('#section2').offsetTop + document.querySelector('#section1').offsetHeight; // End of section 1 + start of section 2

		// ----------------------------------------------------------------------------
		const sectionCone = document.querySelector(".intro-main");
		const sectionPlane = document.querySelector(".strategies");
		const sectionGrid = document.querySelector(".mapBlock");
		const sectionSphere = document.querySelector(".brands");

		// // ***  Scroll points setup ***
		// const scrollPoint0 = 0;
		// // here: cone is spinning (autoanimation)
		// const scrollPoint1 = window.innerHeight * 0.2;
		// // here: cone to horizontal plane (morphing)
		// const scrollPoint2 = window.innerHeight * 0.6;
		// // here: horizontal plane is moving (autoanimation)
		// const scrollPoint3 = window.innerHeight * 2;
		// // here: horizontal plane to vertical plane (morphing)
		// const scrollPoint4 = window.innerHeight * 2.1;
		// // here: vertical plane (static)
		// const scrollPoint5 = window.innerHeight * 3;
		// // here: vertical plane to sphere (morphing)
		// const scrollPoint6 = window.innerHeight * 3.5;
		// // here: sphere is spinning (autoanimation)
		// const scrollPoint7 = window.innerHeight * 4.5;
		// // here: sphere is go away together with the canvas (to top with scroll)
		// ----------------------------------------------------------------------------

		// Scene setup
		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(65, window.innerWidth / window.innerHeight, 1, 500);
		const renderer = new THREE.WebGLRenderer({alpha: true});
		renderer.setSize(window.innerWidth, window.innerHeight);

		scene.background = new THREE.Color(0x3c69ff); // color of the background

		renderer.setClearColor(0x000000, 0);

		// document.body.insertBefore(renderer.domElement, document.body.firstChild);
		morphing.prepend(renderer.domElement);
		renderer.domElement.id = "morphingCanvas";
		camera.position.z = cameraDistance;
		camera.position.y = 4;
		camera.lookAt(0, 0, 0);


		// Composer setup (for blur)
		const composer = new THREE.EffectComposer(renderer);
		const renderPass = new THREE.RenderPass(scene, camera);
		composer.addPass(renderPass);

		const bokehPass = new THREE.BokehPass(scene, camera, {
			focus: 300,         // Increase the value to focus on more remote objects
			aperture: 0.0001,   // Very low value for a minimum blurred diaphragm
			maxblur: 0.002      // Maximum blur
		});
		// composer.addPass(bokehPass);

		// Geometry and texture setup
		const coneInnerGeometry = new THREE.CylinderGeometry(coneInnerRadiusTop, coneInnerRadiusBottom, coneInnerHeight, coneInnerSegmentsQty, coneInnerSegmentsQty, true);
		const coneOuterGeometry = new THREE.CylinderGeometry(coneOuterRadiusTop, coneOuterRadiusBottom, coneOuterHeight, coneOuterSegmentsQty, coneOuterSegmentsQty, true);
		const coneMorphGeometry = new THREE.CylinderGeometry(coneOuterRadiusTop, coneOuterRadiusBottom, coneOuterHeight, planeSegmentsQty, planeSegmentsQty, true);
		const planeGeometry = new THREE.PlaneGeometry(planeWidth, planeWidth, planeSegmentsQty, planeSegmentsQty);
		const sphereGeometry = new THREE.SphereGeometry(sphereRadius, planeSegmentsQty, planeSegmentsQty);
		const infinitePlaneGeometry = new THREE.PlaneGeometry(infinitePlaneWidth, infinitePlaneWidth, infinitePlaneSegments, infinitePlaneSegments);

		const textureLoader = new THREE.TextureLoader();
		const circleTexture = textureLoader.load(pointTexture);

		const material = new THREE.PointsMaterial({
			color: 0xffffff,
			size: pointSize,
			map: circleTexture,
			transparent: true,
			opacity: 1,
			alphaTest: 0.5
		});

		const materialForInfinitePlane = new THREE.PointsMaterial({
			color: 0xffffff,
			size: 0.15,
			map: circleTexture,
			transparent: true,
			opacity: 1,
			alphaTest: 0.5
		});

		// Create double cone
		const pointsConeInner = new THREE.Points(coneInnerGeometry, material);
		const pointsConeOuter = new THREE.Points(coneOuterGeometry, material);
		const pointsConeMorph = new THREE.Points(coneMorphGeometry, material);

		pointsConeInner.position.y = -8;
		pointsConeOuter.position.y = -8;
		pointsConeMorph.position.y = -8;

		pointsConeMorph.position.z = -5;

		pointsConeMorph.visible = false;

		const infinitePlane1 = new THREE.Points(infinitePlaneGeometry, materialForInfinitePlane);
		const infinitePlane2 = new THREE.Points(infinitePlaneGeometry, materialForInfinitePlane);

		infinitePlane1.rotation.x = Math.PI / 2;
		infinitePlane2.rotation.x = Math.PI / 2;
		infinitePlane1.position.y = -8;
		infinitePlane2.position.y = -8;
		infinitePlane1.position.z = 0;
		infinitePlane2.position.z = -infinitePlaneDepth;
		infinitePlane1.visible = false;
		infinitePlane2.visible = false;

		scene.add(infinitePlane1);
		scene.add(infinitePlane2);
		scene.add(pointsConeInner);
		scene.add(pointsConeOuter);
		scene.add(pointsConeMorph);

		// Store vertices for morphing
		const coneMorphVertices = coneMorphGeometry.attributes.position.array.slice();
		const planeVertices = planeGeometry.attributes.position.array.slice();
		const sphereVertices = sphereGeometry.attributes.position.array.slice();

		const uniformScale = 5;
		pointsConeInner.scale.set(uniformScale, uniformScale, uniformScale);
		pointsConeOuter.scale.set(uniformScale, uniformScale, uniformScale);
		pointsConeMorph.scale.set(uniformScale, uniformScale, uniformScale);

		//* ADDED:
		const cylinderGeometry = new THREE.CylinderGeometry(coneOuterRadiusTop / 2, coneOuterRadiusTop / 2, coneOuterHeight, planeSegmentsQty, planeSegmentsQty, true);
		const cylinderVertices = cylinderGeometry.attributes.position.array.slice();

		//* ******

		function setCameraDistance() {
			const screenWidth = window.innerWidth;
			const minWidth = 500;
			const maxWidth = 1500;
			const minDistance = 90;
			const maxDistance = 50;

			const t = Math.min(Math.max((screenWidth - minWidth) / (maxWidth - minWidth), 0), 1);
			const distance = minDistance + (maxDistance - minDistance) * t;

			camera.position.z = distance;
		}

		setCameraDistance();

		function updateGeometry(geometry, startVertices, endVertices, progress) {
			for (let i = 0; i < geometry.attributes.position.count; i++) {
				const ix = i * 3;

				const startX = startVertices[ix];
				const startY = startVertices[ix + 1];
				const startZ = startVertices[ix + 2];

				const endX = endVertices[ix];
				const endY = endVertices[ix + 1];
				const endZ = endVertices[ix + 2];

				const x = startX + (endX - startX) * progress;
				const y = startY + (endY - startY) * progress;
				const z = startZ + (endZ - startZ) * progress;

				geometry.attributes.position.setXYZ(i, x, y, z);
			}
			geometry.attributes.position.needsUpdate = true;
		}

		function updateGeometry2(geometry, startVertices, endVertices, progress) {
			for (let i = 0; i < geometry.attributes.position.count; i++) {
				const ix = i * 3;

				const startX = startVertices[ix];
				const startY = startVertices[ix + 1];
				const startZ = startVertices[ix + 2];

				const endX = endVertices[ix];
				const endY = endVertices[ix + 1];
				const endZ = endVertices[ix + 2];

				const x = startX - (endX + startX) * progress;
				const y = startY + (endY - startY) * progress;
				const z = startZ - (endZ + startZ) * progress;

				geometry.attributes.position.setXYZ(i, x, y, z);
			}
			geometry.attributes.position.needsUpdate = true;
		}


		// Setup animations
		let rotateCones = true;
		let rotateSphere = false;
		let moveInfinitePlane = false;

		ScrollTrigger.create({
			// start: () => scrollPoint0,
			start: 0,
			// end: () => scrollPoint1,
			end: () => sectionCone.getBoundingClientRect().top,
			onEnter: () => {
				rotateCones = true;
				bokehPass.enabled = true;
				pointsConeMorph.visible = false;
				// resetInfinitePlanes();
			},
			onLeaveBack: () => {
				pointsConeMorph.visible = false;
				// resetInfinitePlanes();
			},
			onEnterBack: () => {
				bokehPass.enabled = true;
				pointsConeMorph.visible = false;
				// resetInfinitePlanes();
			},
			invalidateOnRefresh: true
		});

		// Cone to horizontal plane and start its animation
		ScrollTrigger.create({
			// start: () => scrollPoint1,
			start: () => sectionCone.getBoundingClientRect().top,
			// end: () => scrollPoint2,
			end: () => sectionPlane.getBoundingClientRect().top,
			onUpdate: (self) => {
				if (self.progress < 0.5) {
					const morphProgress = self.progress / 0.5;
					updateGeometry(coneMorphGeometry, coneMorphVertices, cylinderVertices, morphProgress);
				} else {
					const morphProgress = (self.progress - 0.5) / 0.5;
					updateGeometry2(coneMorphGeometry, cylinderVertices, planeVertices, morphProgress);
				}
			},
			onEnter: () => {
				bokehPass.enabled = true;
				pointsConeInner.visible = false;
				pointsConeOuter.visible = false;
				rotateCones = false;
				pointsConeMorph.rotation.z = 0;
				pointsConeMorph.visible = true;
				gsap.to(camera.position, {duration: 1, y: -3});
				gsap.to(pointsConeMorph.rotation, {duration: 1, x: 0});
				stopMovingInfinitePlane();
			},
			onLeaveBack: () => {
				pointsConeMorph.visible = false;
				pointsConeInner.visible = true;
				pointsConeOuter.visible = true;
				rotateCones = true;
				gsap.to(camera.position, {duration: 1, y: 4});
			},
			onEnterBack: () => {
				bokehPass.enabled = true;
				pointsConeInner.visible = false;
				pointsConeOuter.visible = false;
				rotateCones = false;
				pointsConeMorph.rotation.z = 0;
				pointsConeMorph.visible = true;
				gsap.to(camera.position, {duration: 1, y: -3});
				gsap.to(pointsConeMorph.rotation, {duration: 1, x: 0});
				stopMovingInfinitePlane();
			},
			onLeave: () => {
				rotateCones = false;
				pointsConeMorph.rotation.y = 0;
				pointsConeMorph.rotation.z = Math.PI / 2;
				if (window.scrollY < sectionGrid.getBoundingClientRect().top - window.innerHeight / 2) {
					gsap.to(pointsConeMorph.rotation, {
						duration: 0.5,
						x: -Math.PI / 2,
						onComplete: startMovingInfinitePlane,
					});
				}
			},
			invalidateOnRefresh: true
		});

		// horizontal plane morphing to vertical plane
		ScrollTrigger.create({
			// start: () => scrollPoint3,
			start: () => sectionGrid.getBoundingClientRect().top - window.innerHeight,
			// end: () => scrollPoint4,
			end: () => sectionGrid.getBoundingClientRect().top - window.innerHeight / 2,
			onUpdate: (self) => {
				const progress = self.progress;
				pointsConeMorph.rotation.x = gsap.utils.interpolate(-Math.PI / 2, 0, progress);
			},
			onEnter: () => {
				rotateCones = false;
				bokehPass.enabled = false;
				stopMovingInfinitePlane();
				resetInfinitePlanes();
			},
			onLeaveBack: () => {
				startMovingInfinitePlane();
			},
			onEnterBack: () => {
				rotateCones = false;
				bokehPass.enabled = false;
				stopMovingInfinitePlane();
				resetInfinitePlanes();
			},
			onLeave: () => {
				rotateCones = false;
				stopMovingInfinitePlane();
				resetInfinitePlanes();
			},
			invalidateOnRefresh: true
		});

		// static vertical plane
		ScrollTrigger.create({
			// start: () => scrollPoint4,
			start: () => sectionGrid.getBoundingClientRect().top,
			// end: () => scrollPoint5,
			end: () => sectionSphere.getBoundingClientRect().top - window.innerHeight,
			onEnter: () => {
				pointsConeMorph.rotation.x = 0;
				camera.position.y = -4;
				rotateCones = false;
				stopMovingInfinitePlane();
				resetInfinitePlanes();
			},
			onLeaveBack: () => {
			},
			onEnterBack: () => {
				pointsConeMorph.rotation.x = 0;
				camera.position.y = -4;
				rotateCones = false;
				stopMovingInfinitePlane();
				resetInfinitePlanes();
			},
			onLeave: () => {
			},
			invalidateOnRefresh: true
		});

		// Vertical plane morphing to sphere
		ScrollTrigger.create({
			// start: () => scrollPoint5,
			start: () => sectionSphere.getBoundingClientRect().top - window.innerHeight,
			// end: () => scrollPoint6,
			end: () => sectionSphere.getBoundingClientRect().top - window.innerHeight / 2,
			onUpdate: (self) => {
				updateGeometry(coneMorphGeometry, planeVertices, sphereVertices, self.progress);
			},
			onEnter: () => {
				bokehPass.enabled = false;
				stopMovingInfinitePlane();
				resetInfinitePlanes();
				rotateCones = false;
				rotateSphere = false;
				gsap.delayedCall(1, () => {
					stopMovingInfinitePlane();
					resetInfinitePlanes();
				});
			},
			onEnterBack: () => {
				bokehPass.enabled = false;
				stopMovingInfinitePlane();
				resetInfinitePlanes();
				rotateCones = false;
				rotateSphere = false;
				gsap.delayedCall(1, () => {
					stopMovingInfinitePlane();
					resetInfinitePlanes();
				});
			},
			invalidateOnRefresh: true
		});

		// Sphere rotation animation
		ScrollTrigger.create({
			// start: () => scrollPoint6,
			start: () => sectionSphere.getBoundingClientRect().top - window.innerHeight / 2,
			// end: () => scrollPoint7,
			end: () => sectionSphere.getBoundingClientRect().top + window.innerHeight / 2,
			onEnter: () => {
				bokehPass.enabled = false;
				stopMovingInfinitePlane();
				resetInfinitePlanes();
				rotateCones = false;
				rotateSphere = true;
				gsap.delayedCall(1, () => {
					stopMovingInfinitePlane();
					resetInfinitePlanes();
				});
			},
			onEnterBack: () => {
				bokehPass.enabled = false;
				stopMovingInfinitePlane();
				resetInfinitePlanes();
				rotateCones = false;
				rotateSphere = true;
			},
			onLeaveBack: () => {
				rotateSphere = false;
				pointsConeMorph.rotation.x = 0;
			},
			invalidateOnRefresh: true
		});

		// Canvas synchronization with page scroll
		ScrollTrigger.create({
			// start: scrollPoint7,
			start: sectionSphere.getBoundingClientRect().top + window.innerHeight / 2,
			onEnter: () => {
				bokehPass.enabled = false;
				renderer.domElement.style.position = 'absolute';
				renderer.domElement.style.top = `${window.scrollY}px`;
			},
			onLeaveBack: () => {
				renderer.domElement.style.position = 'fixed';
				renderer.domElement.style.top = '0px';
			},
			invalidateOnRefresh: true
		});

		function animate() {
			requestAnimationFrame(animate);
			if (rotateCones) {
				pointsConeInner.rotation.y += 0.004;
				pointsConeOuter.rotation.y += 0.004;
			}
			if (rotateSphere) {
				pointsConeMorph.rotation.x -= 0.004;
			}

			if (moveInfinitePlane) {
				animateInfinitePlane();
			}

			if (pointsConeOuter.visible) {
				resetInfinitePlanes();
			}

			if (window.scrollY < sectionCone.getBoundingClientRect().top) {
				pointsConeMorph.visible = false;
			}

			renderer.render(scene, camera);
			composer.render();
		}

		function animateInfinitePlane() {
			infinitePlane1.position.z += 0.01;
			infinitePlane2.position.z += 0.01;

			if (infinitePlane1.position.z > infinitePlaneDepth) {
				infinitePlane1.position.z = infinitePlane2.position.z - infinitePlaneDepth;
			}
			if (infinitePlane2.position.z > infinitePlaneDepth) {
				infinitePlane2.position.z = infinitePlane1.position.z - infinitePlaneDepth;
			}
		}

		function startMovingInfinitePlane() {
			pointsConeMorph.visible = false;
			moveInfinitePlane = true;
			infinitePlane1.visible = true;
			infinitePlane2.visible = true;
		}

		function stopMovingInfinitePlane() {
			pointsConeMorph.visible = true;
			moveInfinitePlane = false;
			infinitePlane1.visible = false;
			infinitePlane2.visible = false;
		}

		function resetInfinitePlanes() {
			moveInfinitePlane = false;
			infinitePlane1.position.z = 0;
			infinitePlane2.position.z = -infinitePlaneDepth;
			infinitePlane1.visible = false;
			infinitePlane2.visible = false;
		}

		animate();

		window.addEventListener('resize', () => {
			camera.aspect = window.innerWidth / window.innerHeight;
			camera.updateProjectionMatrix();
			renderer.setSize(window.innerWidth, window.innerHeight);
			composer.setSize(window.innerWidth, window.innerHeight);

			setCameraDistance(); // Update camera distance on window resize
		});
	}

	// ScrollTrigger.sort();
});
